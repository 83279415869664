import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/saving"

type SavingState = Readonly<{
  dataIsChanged: boolean
}>

const initialState: SavingState = {
  dataIsChanged: false,
}

type SavingActions = ActionType<typeof actions>

export default (state = initialState, action: SavingActions): SavingState => {
  switch (action.type) {
    case getType(actions.requestSendChangesComplete):
      return action.payload.success ? { ...state, dataIsChanged: false } : state
    case getType(actions.setDataIsChanged):
      return { ...state, dataIsChanged: action.payload }
    default:
      return state
  }
}
