export enum filterTypes {
  TOGGLE_FILTER_STATUS = "@@FILTERS/TOGGLE_FILTER_STATUS",
  SET_FILTER_STATUS = "@@FILTERS/SET_FILTER_STATUS",
  TOGGLE_HSM = "@@FILTERS/TOGGLE_HSM",
  SET_HSM = "@@FILTERS/SET_HSM",
  SET_INITIAL_STATE = "@@FILTERS/SET_INITIAL_STATE",
}

export type IActiveNames = Readonly<{
  restorations: boolean
  apical: boolean
  caries: boolean
  calculus: boolean
  nervus: boolean
}>
