import { Detection } from "library/common/types/dataStructureTypes"

export const filterCaries = (caries: Detection[]) =>
  filterOldHsm(
    caries.map((c) =>
      c.subtype === "dentin_caries" ? { ...c, subtype: "caries" } : c
    )
  )

export const filterOldHsm = (detections: Detection[]) =>
  detections.filter((d) => !d.subtype || !d.subtype.endsWith("_F3"))
