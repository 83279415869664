import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/xrays"

export interface IXray {
  id: string
  kind: string
  patientName: string
  patientId: string
  dateOfBirth: string
  imageDate: string
  uploadDate: string
  reportDate: string
  isImageHorizontallyFlipped: boolean
  isImageRotated: boolean
}

type XraysState = Readonly<{
  xrays: IXray[]
  isLoading: boolean
  totalPages: number
  currentPage: number
}>

const initialState: XraysState = {
  xrays: [],
  isLoading: false,
  totalPages: 1,
  currentPage: 1,
}

type XraysActions = ActionType<typeof actions>

export default (state = initialState, action: XraysActions): XraysState => {
  switch (action.type) {
    case getType(actions.setAllXRays):
      return {
        ...state,
        xrays: action.payload,
        totalPages: Math.ceil(action.payload.length / 10),
      }

    case getType(actions.setXRaysIsLoading):
      return {
        ...state,
        isLoading: action.payload,
      }

    case getType(actions.deleteXraySuccess):
      const xrays = state.xrays.filter(
        (xray: IXray) => xray.id !== action.payload
      )
      const totalPages = Math.ceil(xrays.length / 10) || 1
      const currentPage =
        state.currentPage > totalPages ? totalPages : state.currentPage

      return {
        ...state,
        xrays,
        totalPages,
        currentPage,
      }

    case getType(actions.setCurrentPage):
      return {
        ...state,
        currentPage: action.payload,
      }

    default:
      return state
  }
}
