export enum SavingTypes {
  REQUEST_SEND_CHANGES = "@@SAVING/REQUEST_SEND_CHANGES",
  REQUEST_SEND_CHANGES_COMPLETE = "@@SAVING/REQUEST_SEND_CHANGES_COMPLETE",
  SET_DATA_IS_CHANGED = "@@SAVING/SET_DATA_IS_CHANGED",
  REQUEST_SEND_EVENT = "@@SAVING/SEND_EVENT",
}

export interface SaveComplete {
  success: boolean
  id: string
}
