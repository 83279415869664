import "react-app-polyfill/ie9"
import "es7-object-polyfill"
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { history, store } from "core/store/configureStore"
import configureI18n from "core/i18n/configureI18n"

import Routes from "core/Routes"

import "resources/styles/index.scss"

async function render() {
  await configureI18n()

  createRoot(document.getElementById("root")!).render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  )
}

void render()
