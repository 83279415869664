import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/imageControls"

export enum ActiveFilter {
  NAVIGATION = "navigation",
  CONTRAST = "contrast",
  OPACITY = "opacity",
}

export type ResetableImageFilters = Readonly<{
  top: number
  left: number
  scale: number
}>

type ImageControlsState = Readonly<
  {
    width: number | null
    height: number | null
    activeFilter: ActiveFilter
    isFullscreen: boolean
    usedFullscreen: boolean
  } & ResetableImageFilters
>

const resettableFilters = {
  top: 0,
  left: 0,
  scale: 1,
}

export const initialState: ImageControlsState = {
  ...resettableFilters,
  width: null,
  height: null,
  activeFilter: ActiveFilter.NAVIGATION,
  isFullscreen: false,
  usedFullscreen: false,
}

type ImageControlsActions = ActionType<typeof actions>

export default (
  state = initialState,
  action: ImageControlsActions
): ImageControlsState => {
  switch (action.type) {
    case getType(actions.setZoom):
      const { top, left, scale } = action.payload

      return { ...state, top, left, scale }

    case getType(actions.setDimensions):
      const { width, height } = action.payload

      return { ...state, width, height }

    case getType(actions.setActiveFilterSuccess):
      return { ...state, activeFilter: action.payload }

    case getType(actions.setInitialState):
      return { ...initialState, width: state.width, height: state.height }

    case getType(actions.toggleFullscreen):
      return {
        ...state,
        isFullscreen: !state.isFullscreen,
        usedFullscreen: state.isFullscreen || state.usedFullscreen,
      }

    default:
      return state
  }
}
