import { getParser } from "bowser"

export const browser = getParser(window.navigator.userAgent)
export const isBrowserValid = browser.satisfies({
  chrome: ">=70",
  firefox: ">=60",
  safari: ">=9",
  edge: ">=79",
  electron: ">=18",
})

export const isDesktop = !(
  "ontouchstart" in window || navigator.maxTouchPoints > 0
)
