import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/modal"

export enum Modals {
  SELECT_RADIOGRAPH = "SELECT_RADIOGRAPH",
  ROTATE_X_RAY_IMAGE = "ROTATE_X_RAY_IMAGE",
  CHANGE_IMAGE_META = "CHANGE_IMAGE_META",
  RESET_PREDICTIONS = "RESET_PREDICTIONS",
  REANALYSE_ALERT = "REANALYSE_ALERT",
  ADD_PERI_ANNOTATION = "ADD_PERI_ANNOTATION",
}

type ModalState = Readonly<{
  openedModal: Modals | null
}>

const initialState: ModalState = {
  openedModal: null,
}

type ModalActions = ActionType<typeof actions>

export default (state = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case getType(actions.openModal):
      return { ...state, openedModal: action.payload }

    case getType(actions.closeModal):
      return { ...state, openedModal: null }

    default:
      return state
  }
}
