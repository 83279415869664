import { createSelector } from "reselect"

import { RootState } from "core/store/configureStore"
import {
  isUpper,
  offsetFromLeft,
} from "library/common/selectors/utils/toothNumbers"

export const getAdjustments = (state: RootState) => state.adjustments.stack
export const getShiftingTeeth = (state: RootState) =>
  state.adjustments.shiftingTeeth
export const getTeethAreShifting = (state: RootState) =>
  state.adjustments.teethAreShifting
export const getMovingStack = (state: RootState) =>
  state.adjustments.movingStack
export const getMovingAnnotation = (state: RootState) =>
  state.adjustments.movingAnnotation

export const getShiftingBounds = createSelector(
  [getShiftingTeeth],
  (shiftingTeeth) => {
    if (shiftingTeeth.length == 0) return null

    const selectionStart = shiftingTeeth[0]
    return {
      upper: isUpper(selectionStart),
      start: offsetFromLeft(selectionStart),
      to: shiftingTeeth.length,
    }
  }
)
