import { createAction } from "typesafe-actions"

import { XraysTypes } from "../types/xraysTypes"
import { IXray } from "../reducers/xraysReducer"

export const getAllXRays = createAction(XraysTypes.GET_ALL_XRAYS)()
export const setAllXRays = createAction(XraysTypes.SET_ALL_XRAYS)<IXray[]>()
export const setXRaysIsLoading = createAction(
  XraysTypes.SET_ALL_XRAYS_IS_LOADING
)<boolean>()
export const deleteXrayStart = createAction(
  XraysTypes.DELETE_XRAY_START
)<string>()
export const deleteXraySuccess = createAction(
  XraysTypes.DELETE_XRAY_SUCCESS
)<string>()
export const setCurrentPage = createAction(
  XraysTypes.SET_CURRENT_PAGE
)<number>()
