import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/legend"

type LegendState = Readonly<{
  isLegendOpen: boolean
}>

const initialState: LegendState = {
  isLegendOpen: true,
}

type LegendActions = ActionType<typeof actions>

export default (state = initialState, action: LegendActions): LegendState => {
  switch (action.type) {
    case getType(actions.toggleLegend):
      return {
        ...state,
        isLegendOpen: !state.isLegendOpen,
      }

    default:
      return state
  }
}
