import { UserChange } from "library/common/types/dataStructureTypes"
import { Tooth } from "library/common/types/teethTypes"

export function flipTooth(toothNumber: number, flip = true) {
  if (!flip) return toothNumber

  const quadrant = Math.floor(toothNumber / 10)
  return quadrant == 1 || quadrant == 3 ? toothNumber + 10 : toothNumber - 10
}

export const visualQuadrant = (tooth: number, flip: boolean) =>
  Math.floor(flipTooth(tooth, flip) / 10)

/**
 * Flip the tooth value of a list of objects that contains `toothName` as key
 * when `flip` is true (by default) or return same object list un-touched
 */
export function flipTeeth<Type extends Tooth>(
  objectList: Type[],
  flip = true
): Type[] {
  return flip
    ? objectList.map((obj) => ({
        ...obj,
        toothName: flipTooth(obj.toothName),
      }))
    : objectList
}

export function flipChanges(changes: UserChange[], flip = true): UserChange[] {
  return flip
    ? changes.map((change) =>
        change.newTooth
          ? {
              ...change,
              newTooth: flipTooth(change.newTooth),
            }
          : change
      )
    : changes
}

export function flipMovedTeeth(
  movedTeeth: Record<string, number>
): Record<string, number> {
  const newMovedTeeth: Record<string, number> = {}
  Object.entries(movedTeeth).forEach(([k, v]) => {
    newMovedTeeth[`${flipTooth(+k)}`] = flipTooth(v)
  })
  return newMovedTeeth
}

export function applyMovedTeeth<Type extends Tooth>(
  objects: Type[],
  movedTeeth: Record<string, number>
): Type[] {
  if (!movedTeeth) return objects
  // update the toothNames to account for movedTeeth
  // Don't include teeth that were overwritten
  const overwrittenTeeth = Object.values(movedTeeth).filter(
    (t) => !movedTeeth[t]
  )
  return objects
    .filter((a) => !overwrittenTeeth.includes(a.toothName))
    .map((a) => ({
      ...a,
      toothName: movedTeeth[a.toothName] || a.toothName,
    }))
}
