import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { isBeta } from "library/utilities/beta"
import localStorage from "library/utilities/localStorage"
import de from "resources/locales/de.json"
import deBeta from "resources/locales/deBeta.json"
import en from "resources/locales/en.json"
import enBeta from "resources/locales/enBeta.json"
import es from "resources/locales/es.json"
import esBeta from "resources/locales/esBeta.json"
import fr from "resources/locales/fr.json"
import frBeta from "resources/locales/frBeta.json"
import it from "resources/locales/it.json"
import itBeta from "resources/locales/itBeta.json"
import nl from "resources/locales/nl.json"
import nlBeta from "resources/locales/nlBeta.json"
import pl from "resources/locales/pl.json"
import plBeta from "resources/locales/plBeta.json"
import pt from "resources/locales/pt.json"
import ptBeta from "resources/locales/ptBeta.json"
import version from "resources/locales/version.json"
import { supportedLanguages } from "./utils"

let locale = localStorage.getItem("locale")

if (!locale) {
  const language = window.navigator.language.slice(0, 2)
  locale = supportedLanguages.includes(language) ? language : "de"

  localStorage.setItem("locale", locale)
}

const lng = locale

function mergeDictionary(a: object | undefined, b: object) {
  // merge b into a
  const result = { ...a }

  Object.entries(b).forEach(([k, value]) => {
    result[k] =
      value.constructor === Object ? mergeDictionary(result[k], value) : value
  })

  return result
}

// keep this alphabetical to make it easier to compare with the list of JSON files we have
const baseTranslations = {
  de,
  deBeta,
  en,
  enBeta,
  es,
  esBeta,
  fr,
  frBeta,
  it,
  itBeta,
  nl,
  nlBeta,
  pl,
  plBeta,
  pt,
  ptBeta,
}

const enTranslations = isBeta
  ? mergeDictionary(baseTranslations.en, baseTranslations.enBeta)
  : baseTranslations.en

const resources = {}
supportedLanguages.forEach((lang) => {
  const base = mergeDictionary(
    enTranslations,
    mergeDictionary(baseTranslations[lang], version)
  )
  resources[lang] = {
    translation: isBeta
      ? mergeDictionary(base, baseTranslations[lang + "Beta"])
      : base,
  }
})

const configureI18n = async () => {
  await i18n.use(initReactI18next).init({
    compatibilityJSON: "v3",
    lng,
    resources,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        "br",
        "strong",
        "i",
        "ul",
        "ol",
        "li",
        "h1",
        "h2",
        "h3",
        "hr",
        "p",
      ],
    },
    interpolation: {
      escapeValue: false,
    },
  })
}

export default configureI18n
