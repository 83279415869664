export const offsetFromLeft = (tooth: number) => {
  if (tooth < 20) {
    return 18 - tooth
  } else if (tooth < 30) {
    return tooth - 21 + 8
  } else if (tooth < 40) {
    return tooth - 31 + 8
  } else {
    return 48 - tooth
  }
}

export const isUpper = (tooth: number) => tooth < 30
