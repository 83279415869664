import { createAction } from "typesafe-actions"

import { UploadTypes } from "../types/uploadTypes"
import { ToothWithDetection, DataImage } from "../reducers/uploadReducer"

export interface IMetaData {
  dateOfBirth: string | null
  imageDate: string | null
  patientName: string
  patientID: string
}

interface ActionGetDataImageSuccess {
  dataImage: DataImage
  newDataTooth: ToothWithDetection[]
  meta: IMetaData & {
    kind: string
    imageDateCreate: string
  }
}

export interface ActionWithData {
  meta: {
    patientID?: string
    patientName?: string
    dateOfBirth?: string
    externalImageUID?: string
    imageDateCreate: string
    imageDate: string
    fileName: string
  }
  data: string | ArrayBuffer | null
}

export interface IReportProblem {
  info: {
    description: string
    userName: string
    email: string
  }
  screenshot: string
  url: string
}

export const uploadActionNewImage = createAction(
  UploadTypes.UPLOAD_ACTION_NEW_IMAGE
)()
export const uploadActionPreloaderImage = createAction(
  UploadTypes.UPLOAD_ACTION_PRELOADER_IMAGE
)()
export const uploadActionError = createAction(UploadTypes.UPLOAD_ACTION_ERROR)()
export const uploadActionSuccess = createAction(
  UploadTypes.UPLOAD_ACTION_SUCCESS
)()
export const uploadActionGetDataImageSuccess = createAction(
  UploadTypes.UPLOAD_ACTION_DATA_IMAGE_SUCCESS
)<ActionGetDataImageSuccess>()
export const uploadActionWithData = createAction(
  UploadTypes.UPLOAD_ACTION_WITH_DATA
)<ActionWithData>()
export const uploadActionSaveUserMetaData = createAction(
  UploadTypes.UPLOAD_ACTION_SAVE_USER_META_DATA
)<IMetaData>()
export const sendReportProblem = createAction(
  UploadTypes.UPLOAD_ACTION_SEND_REPORT_PROBLEM
)<IReportProblem>()
export const setReportProblemId = createAction(
  UploadTypes.UPLOAD_ACTION_SET_REPORT_PROBLEM_ID
)<string>()
export const setShouldTakeScreenshot = createAction(
  UploadTypes.UPLOAD_ACTION_SET_SHOULD_TAKE_SCREENSHOT
)<boolean>()
export const setScreenshot = createAction(
  UploadTypes.UPLOAD_ACTION_SET_SCREENSHOT
)<string>()
export const uploadActionSetImageSizeError = createAction(
  UploadTypes.UPLOAD_ACTION_SET_IMAGE_SIZE_ERROR
)<string>()
export const setDemoMode = createAction(
  UploadTypes.UPLOAD_ACTION_SET_DEMO_MODE
)()
