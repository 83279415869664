import { createAction } from "typesafe-actions"

import { filterTypes } from "library/common/types/filterTypes"
import { FilterStatus, FiltersState } from "../reducers/filtersReducer"

export const toggleFilterStatus = createAction(
  filterTypes.TOGGLE_FILTER_STATUS
)<FilterStatus>()
export const setFiltersStatus = createAction(
  filterTypes.SET_FILTER_STATUS
)<FiltersState>()
export const toggleHSM = createAction(filterTypes.TOGGLE_HSM)()
export const setHSM = createAction(filterTypes.SET_HSM)<boolean>()
export const setInitialState = createAction(filterTypes.SET_INITIAL_STATE)()
