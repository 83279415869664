export enum ImageControlsTypes {
  SET_ZOOM = "@@IMAGE_CONTROLS/SET_ZOOM",
  SET_ZOOM_SUCCESS = "@@IMAGE_CONTROLS/SET_ZOOM_SUCCESS",
  SET_DIMENSIONS = "@@IMAGE_CONTROLS/SET_DIMENSIONS",
  SET_ZOOM_CLICK = "@@IMAGE_CONTROLS/SET_ZOOM_CLICK",
  SET_ACTIVE_FILTER = "@@IMAGE_CONTROLS/SET_ACTIVE_FILTER",
  SET_ACTIVE_FILTER_SUCCESS = "@@IMAGE_CONTROLS/SET_ACTIVE_FILTER_SUCCESS",
  REDUCER_STATE_CHANGE = "@@IMAGE_CONTROLS/REDUCER_STATE_CHANGE",
  SET_INITIAL_STATE = "@@IMAGE_CONTROLS/SET_INITIAL_STATE",
  TOGGLE_FULLSCREEN = "@@IMAGE_CONTROLS/TOGGLE_FULLSCREEN",
  SET_USED_FULLSCREEN = "@@IMAGE/SET_USED_FULLSCREEN",
}
