import { put, takeLatest, select, takeEvery } from "redux-saga/effects"

import { adjustmentTypes } from "library/common/types/adjustmentTypes"
import { ImageControlsTypes } from "library/common/types/imageControlsTypes"
import { filterTypes } from "library/common/types/filterTypes"
import { UserTypes } from "library/common/types/userTypes"
import { IMeta, ServerDataTypes } from "library/common/types/serverDataTypes"

import { ActiveFilter } from "library/common/reducers/imageControlsReducer"

import * as imageControlActions from "library/common/actions/imageControls"
import * as withHistoryActions from "library/common/actions/withHistory"
import * as teethActions from "library/common/actions/teeth"
import * as adjustmentActions from "library/common/actions/adjustments"
import * as serverDataActions from "library/common/actions/serverData"

import * as imageControlSelectors from "library/common/selectors/imageControls"
import * as serverDataSelectors from "library/common/selectors/serverData"
import * as adjustmentSelectors from "library/common/selectors/adjustments"

import { flipTooth } from "library/utilities/tooth"
import { getBonelossPro } from "../selectors/image"
import { Boneloss } from "../types/dataStructureTypes"
import { getBoneloss } from "../selectors/entities"
import { imageTypes } from "../types/imageTypes"

function* setZoomClickSaga({
  payload: direction,
}: {
  type: ImageControlsTypes.SET_ZOOM_CLICK
  payload: string
}) {
  const activeFilter: ActiveFilter = yield select(
    imageControlSelectors.getActiveFilter
  )
  const scale: number = yield select(imageControlSelectors.getZoomScale)
  const width: number = yield select(imageControlSelectors.getImageWidth)
  const height: number = yield select(imageControlSelectors.getImageHeight)
  const left: number = yield select(imageControlSelectors.getImageLeft)
  const top: number = yield select(imageControlSelectors.getImageTop)

  let step = 1.5

  if (direction === "in") step = scale < 1 ? 0.1 : step
  if (direction === "out") {
    if (scale > 1 && scale < 2) {
      const nextPartialState = { left: 0, top: 0, scale: 1 }
      yield put(imageControlActions.setZoom(nextPartialState))

      return
    }
  }

  let newScale = direction === "in" ? scale * step : scale / step

  if (newScale > 0.9 && newScale < 1) newScale = 1
  if (newScale > 1e3) newScale = 1e3

  const halfWidth = width / 2
  const halfHeight = height / 2
  const reducedScale = newScale - 1

  let newLeft = Math.max(
    Math.min(left, halfWidth * reducedScale),
    -halfWidth * reducedScale
  )
  let newTop = Math.max(
    Math.min(top, halfHeight * reducedScale),
    -halfHeight * reducedScale
  )

  if (newScale <= 0.1) {
    newScale = 0.1
    newLeft = 0
    newTop = 0
  }

  if (newScale < 1) {
    newTop = 0
    newLeft = 0
  }

  if (activeFilter !== ActiveFilter.NAVIGATION) {
    yield put(
      imageControlActions.setActiveFilterSuccess(ActiveFilter.NAVIGATION)
    )
  }
  if (newScale >= 1) {
    const nextPartialState = { left: newLeft, top: newTop, scale: newScale }
    yield put(imageControlActions.setZoom(nextPartialState))
  }
}

function* setActiveFilterSaga({
  payload: nextActiveFilter,
}: {
  type: ImageControlsTypes.SET_ACTIVE_FILTER
  payload: ActiveFilter
}) {
  const activeFilter: string = yield select(
    imageControlSelectors.getActiveFilter
  )
  if (nextActiveFilter === activeFilter) {
    yield put(
      imageControlActions.setActiveFilterSuccess(ActiveFilter.NAVIGATION)
    )

    return
  }
  yield put(imageControlActions.setActiveFilterSuccess(nextActiveFilter))
}

function* disableFullscreen() {
  const isFullscreen: boolean = yield select(
    imageControlSelectors.getIsFullscreen
  )
  if (isFullscreen) {
    yield put(imageControlActions.toggleFullscreen())
  }
}

function* flipImageSaga() {
  const activeTooth: number | null = yield select(
    serverDataSelectors.getActiveTooth
  )
  const teethAreShifting: boolean = yield select(
    adjustmentSelectors.getTeethAreShifting
  )
  const shiftingTeeth: number[] = yield select(
    adjustmentSelectors.getShiftingTeeth
  )

  yield put(withHistoryActions.rememberState())
  yield put(serverDataActions.toggleFlipImage())

  // when a tooth is selected and we flip the image, the selected tooth stays in the wrong side
  if (activeTooth) {
    yield put(teethActions.setActiveTooth(flipTooth(activeTooth)))
  }
  if (teethAreShifting) {
    // The highlight square that tells the user which teeth are being moved currently takes as a reference
    //   the tooth found in `shiftingTeeth` and with the length of `shiftingTeeth` calculates how many tooth spaces
    //   will cover the highlighted square from left to right, so in order to flip the image correctly we must reverse
    //   the order of the list in `shiftingTeeth`
    const shiftedTeethFlipped = shiftingTeeth
      .slice(0)
      .reverse()
      .map((tooth: number) => flipTooth(tooth))
    yield put(adjustmentActions.expandShiftingToSuccess(shiftedTeethFlipped))
  }
}

// Reverse shiftingTeeth array when clicking display flipped button
function* flipImageHorizontallySaga() {
  const teethAreShifting: boolean = yield select(
    adjustmentSelectors.getTeethAreShifting
  )
  const shiftingTeeth: number[] = yield select(
    adjustmentSelectors.getShiftingTeeth
  )

  if (teethAreShifting) {
    const shiftedTeethFlipped = [...shiftingTeeth].reverse()
    yield put(adjustmentActions.expandShiftingToSuccess(shiftedTeethFlipped))
  }
}

function* zoomPerio() {
  const boneLossPro: boolean = yield select(getBonelossPro)
  const boneLoss: Boneloss = yield select(getBoneloss)
  const meta: IMeta = yield select(serverDataSelectors.getImageMeta)
  const imageWidth: number = meta["imageWidth"]
  const imageHeight: number = meta["imageHeight"]
  const viewportWidth: number = yield select(
    imageControlSelectors.getImageWidth
  )
  const viewportHeight: number = yield select(
    imageControlSelectors.getImageHeight
  )

  if (
    !boneLoss ||
    !boneLossPro ||
    !boneLoss.pb_roi ||
    !imageWidth ||
    !imageHeight ||
    !boneLoss.pb_roi.width ||
    !boneLoss.pb_roi.height
  ) {
    yield put(imageControlActions.setZoom({ left: 0, top: 0, scale: 1 }))
    return
  }

  const zoomFactorImg = Math.min(
    viewportWidth / imageWidth,
    viewportHeight / imageHeight
  )

  const zoomFactorRoi = Math.min(
    viewportWidth / boneLoss.pb_roi.width,
    viewportHeight / boneLoss.pb_roi.height
  )

  yield put(
    imageControlActions.setZoom({
      left:
        ((imageWidth - boneLoss.pb_roi.width) / 2 - boneLoss.pb_roi.x) *
        zoomFactorRoi,
      top:
        ((imageHeight - boneLoss.pb_roi.height) / 2 - boneLoss.pb_roi.y) *
        zoomFactorRoi,
      scale: zoomFactorRoi / zoomFactorImg,
    })
  )
}

export default function* watchImageControls() {
  yield takeLatest(ImageControlsTypes.SET_ZOOM_CLICK, setZoomClickSaga)
  yield takeLatest(ImageControlsTypes.SET_ACTIVE_FILTER, setActiveFilterSaga)
  yield takeLatest(UserTypes.TOGGLE_BONELOSS_PRO, zoomPerio)
  yield takeLatest(imageTypes.SHOW_ANNOTATIONS, zoomPerio)
  yield takeEvery(
    [
      UserTypes.TOGGLE_CARIES_PRO,
      filterTypes.TOGGLE_HSM,
      adjustmentTypes.TOGGLE_TEETH_ARE_SHIFTING,
    ],
    disableFullscreen
  )
  yield takeEvery(ServerDataTypes.FLIP_IMAGE, flipImageSaga)
  yield takeEvery(
    ServerDataTypes.TOGGLE_DISPLAY_HORIZONTALLY_FLIPPED,
    flipImageHorizontallySaga
  )
}
