import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"
import { getCariesProProfile, getBonelossProProfile } from "./user"

export const getIsProcessed = (state: RootState) => state.image.isProcessed
export const getAnnotationsShown = (state: RootState) =>
  state.image.annotationsShown

export const getOpenDateMs = (state: RootState) => state.image.openDateMs
export const getPdfReportData = (state: RootState) => state.image.pdfReportData

export const getShowImmediately = (state: RootState) =>
  state.image.showImmediately

export const getRawCariesPro = (state: RootState) => state.entities.caries

export const getRawBoneLoss = (state: RootState) => state.entities.boneloss

// CARIES PRO
// Only available on bitewings currently
export const isCariesProAvailableOnImage = (state: RootState) =>
  state.serverData.present.imageMeta.kind === "BW"
// when we don't have caries pro available we're in the undefined cariesPro state
// undefined cariesPro removes the key from the changes JSON, which we want to happen unless we're in BW
export const getCariesPro = createSelector(
  [isCariesProAvailableOnImage, getCariesProProfile, getRawCariesPro],
  (cariesProAvailable, cariesProProfile, rawCariesPro) =>
    cariesProAvailable && rawCariesPro.every((r) => r.location !== undefined) // CariesPro exists on radiograph
      ? cariesProProfile
      : undefined
)

// BONELOSS
// Only available on panoramics currently
export const getBonelossProAvailable = (state: RootState) =>
  state.serverData.present.imageMeta.kind === "OPG"
// when we don't have boneloss pro available we're in the undefined bonelossPro state
// undefined bonelossPro removes the key from the changes JSON, which we want to happen unless we're in OPG
export const getBonelossPro = createSelector(
  [getBonelossProAvailable, getBonelossProProfile, getRawBoneLoss],
  (bonelossProAvailable, bonelossProProfile, rawBoneLoss) =>
    bonelossProAvailable && rawBoneLoss !== undefined
      ? bonelossProProfile
      : null
)
