import { createAction } from "typesafe-actions"

import {
  adjustmentTypes,
  AnnotationOnTooth,
  ShiftingSelection,
  PeriAnnotation,
} from "library/common/types/adjustmentTypes"

export const adjustAnnotations = createAction(
  adjustmentTypes.ADJUST_ANNOTATIONS
)<any>()
export const adjustAnnotationsSuccess = createAction(
  adjustmentTypes.ADJUST_ANNOTATIONS_SUCCESS
)<any[]>()
export const moveAnnotation = createAction(
  adjustmentTypes.MOVE_ANNOTATION
)<string>()
export const moveAnnotationSuccess = createAction(
  adjustmentTypes.MOVE_ANNOTATION_SUCCESS
)<any>()
export const moveAnnotationTo = createAction(
  adjustmentTypes.MOVE_ANNOTATION_TO
)<number>()
export const moveAnnotationToSuccess = createAction(
  adjustmentTypes.MOVE_ANNOTATION_TO_SUCCESS
)<any>()
export const toggleAnnotationOnTooth = createAction(
  adjustmentTypes.TOGGLE_ANNOTATION_ON_TOOTH
)<{
  annotation: AnnotationOnTooth
  isChecked: boolean
}>()
export const togglePeriAnnotation = createAction(
  adjustmentTypes.TOGGLE_PERI_ANNOTATION
)<PeriAnnotation>()
export const toggleAnnotationSuccess = createAction(
  adjustmentTypes.TOGGLE_ANNOTATION_SUCCESS
)<any>()
export const startExpanding = createAction(
  adjustmentTypes.START_EXPANDING
)<number>()
export const startExpandingSuccess = createAction(
  adjustmentTypes.START_EXPANDING_SUCCESS
)<number>()
export const expandShiftingTo = createAction(
  adjustmentTypes.EXPAND_SHIFTING_TO
)<ShiftingSelection>()
export const expandShiftingToSuccess = createAction(
  adjustmentTypes.EXPAND_SHIFTING_TO_SUCCESS
)<number[]>()
export const moveShiftingStackTo = createAction(
  adjustmentTypes.MOVE_SHIFTING_STACK_TO
)<number>()
export const setNextActiveStack = createAction(
  adjustmentTypes.SET_NEXT_ACTIVE_STACK
)<number>()
export const setNextActiveStackSuccess = createAction(
  adjustmentTypes.SET_NEXT_ACTIVE_STACK_SUCCESS
)<any>()
export const setInitialState = createAction(adjustmentTypes.SET_INITIAL_STATE)()
export const toggleTeethAreShifting = createAction(
  adjustmentTypes.TOGGLE_TEETH_ARE_SHIFTING
)()
