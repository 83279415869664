export enum ModalTypes {
  OPEN_MODAL = "@@MODAL/OPEN_MODAL",
  CLOSE_MODAL = "@@MODAL/CLOSE_MODAL",
}

// Rearrange to change the help modal button order
export enum HelpModalEntry {
  LEGEND = "legend",
  ANALYSIS = "analysis",
  NAVIGATION = "navigation",
  WHATS_NEW = "new",
  LANGUAGES = "language",
  USER_MANUAL = "manual",
  PRODUCT_INFORMATION = "info",
  GDPR = "privacy",
  IMPRINT = "imprint",
  REPORT_PROBLEM = "problem",
}
