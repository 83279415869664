import React from "react"

import styles from "./commonSpinner.module.scss"

const CommonSpinner = () => (
  <div className={styles.spinner}>
    {[
      ...Array(12)
        .fill("")
        .map((_, i) => <div key={i} />),
    ]}
  </div>
)

export default CommonSpinner
