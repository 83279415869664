import { RootState } from "core/store/configureStore"

export const getImageWidth = (state: RootState) => state.imageControls.width
export const getImageHeight = (state: RootState) => state.imageControls.height
export const getImageLeft = (state: RootState) => state.imageControls.left
export const getImageTop = (state: RootState) => state.imageControls.top
export const getZoomScale = (state: RootState) => state.imageControls.scale
export const getActiveFilter = (state: RootState) =>
  state.imageControls.activeFilter
export const getIsImageFlipped = (state: RootState) =>
  state.serverData.present.imageMeta.isImageHorizontallyFlipped
export const getIsFullscreen = (state: RootState) =>
  state.imageControls.isFullscreen
export const getUsedFullscreen = (state: RootState) =>
  state.imageControls.usedFullscreen
