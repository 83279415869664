enum TeethTypes {
  SET_ACTIVE_TOOTH = "@@TEETH/SET_ACTIVE_TOOTH",
  SAVE_DETECTED_TEETH = "@@TEETH/SAVE_DETECTED_TEETH",
  SET_INITIAL_STATE = "@@TEETH/SET_INITIAL_STATE",
  CREATE_TOOTH = "@@TEETH/CREATE_TOOTH",
  DELETE_TOOTH = "@@TEETH/DELETE_TOOTH",
}

export type Tooth = Readonly<{
  toothName: number
}>

export default TeethTypes
