import { createAction } from "typesafe-actions"

import { ImageControlsTypes } from "../types/imageControlsTypes"

import { ActiveFilter } from "library/common/reducers/imageControlsReducer"

export const setZoomClick = createAction(
  ImageControlsTypes.SET_ZOOM_CLICK
)<string>()
export const setActiveFilter = createAction(
  ImageControlsTypes.SET_ACTIVE_FILTER
)<ActiveFilter>()
export const setActiveFilterSuccess = createAction(
  ImageControlsTypes.SET_ACTIVE_FILTER_SUCCESS
)<ActiveFilter>()
export const setInitialState = createAction(
  ImageControlsTypes.SET_INITIAL_STATE
)()

export const setZoom = createAction(ImageControlsTypes.SET_ZOOM)<{
  left: number
  top: number
  scale: number
}>()

export const setDimensions = createAction(ImageControlsTypes.SET_DIMENSIONS)<{
  width: number
  height: number
}>()

export const toggleFullscreen = createAction(
  ImageControlsTypes.TOGGLE_FULLSCREEN
)()
